
import { computed, defineComponent, ref } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Permissions } from '@/models/Interfaces';
import router from '@/router';
import { generalStore } from '@/store';
import { ProviderProfile } from '@/models/Interfaces';
import InputNumber from 'primevue/inputnumber';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'Profile',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        InputNumber
    },
    async setup(props: any) {
        const { t } = useI18n();
        const loaded = ref(false);
        const permissions = Permissions;
        const canChangeCashier = ref(false);
        const wrongCashierMaximumDiscount = ref(false);
        const wrongCashierMaximumChecks = ref(false);
        const showCashierMaxChecks = ref(false);
        const defaultProfile: ProviderProfile = {
            id: '',
            active: true,
            description: '',
            createdAt: '',
            permissions: [],
            priceTypes: [],
            products: [],
            cashierMaxDicountPercent: null,
            cashierMaxChecks: null
        };
        const profile = ref<ProviderProfile>(defaultProfile);
        if (props.id) {
            swal.showLoading();
            const apiResult = await api.getUserProfileData(props.id);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
            }
            if (apiResult.data) {
                profile.value = apiResult.data;
                canChangeCashier.value = profile.value.permissions.includes(permissions.Cashier);
                if (profile.value.cashierMaxChecks != null) {
                    showCashierMaxChecks.value = true;
                }
            }
            swal.close();
        }
        const priceTypes = await generalStore.dispatch('getPriceTypes');
        const products = await generalStore.dispatch('getMyProducts');

        loaded.value = true;
        async function cancel() {
            router.go(-1);
        }
        async function save() {
            let wasError = false;
            wrongCashierMaximumDiscount.value = false;
            wrongCashierMaximumChecks.value = false;
            if (profile.value.permissions.includes(permissions.CashierChangeDiscount) && profile.value.cashierMaxDicountPercent == null) {
                swal.fire({
                    icon: 'error',
                    text: t('alert.permissions.maximumDiscountRequired')
                });
                wrongCashierMaximumDiscount.value = true;
                wasError = true;
            }
            if (showCashierMaxChecks.value && profile.value.cashierMaxChecks == null) {
                if (!wasError) {
                    swal.fire({
                        icon: 'error',
                        text: t('alert.permissions.maximumCashierChecksRequired')
                    });
                }
                wrongCashierMaximumChecks.value = true;
                wasError = true;
            }
            if (wasError) {
                return;
            }
            swal.showLoading();
            const profileClone = JSON.parse(JSON.stringify(profile.value)) as ProviderProfile;
            profileClone.permissions = [];
            if (profile.value != undefined && profile.value.permissions) {
                profile.value.permissions.forEach(element => {
                    profileClone.permissions.push(parseInt(element.toString()));
                });
            }
            const apiResult = await api.saveUserProfileData(profileClone);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            swal.close();
            router.go(-1);

            // Add a small delay to reload page after routing
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
        function changeCashierMaxDicountPercent(e: any) {
            if (!e.target.checked) {
                profile.value.cashierMaxDicountPercent = null;
            }
        }
        function changeCashierMaxChecks(e: any) {
            if (!e.target.checked) {
                profile.value.cashierMaxChecks = null;
            }
        }
        return {
            cancel,
            save,
            loaded,
            profile,
            permissions,
            priceTypes,
            products,
            canChangeCashier,
            wrongCashierMaximumDiscount,
            wrongCashierMaximumChecks,
            changeCashierMaxDicountPercent,
            showCashierMaxChecks,
            changeCashierMaxChecks
        };
    }
});
